.container {
  border: 2px solid var(--bs-border_one);
  align-content: center;
}

.subHeading {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
}

.infoHeading {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: var(--bs-bodytext-darktwo);
}

.baseHeadingOuterBox {
  background-color: #f6f5f4;
  width: 327px;
  justify-content: center;
  border-radius: 20px;
  display: flex;
}

.baseHeading {
  color: var(--bs-bodytext-darktwo);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
}

.imageStyle {
  cursor: pointer;
}

.genericFormFieldTextStyle {
  color: #616060;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 6px 12px 6px 12px;
}

.genericFormFieldStyle {
  background-color: #e9ecef;
  width: 100%;
  height: auto;
  border-bottom: 1px solid var(bs-border_one);
}

.informationTitleHeading {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
