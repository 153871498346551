.row.banner-button {
    justify-content: center;
    text-align: center;
}

.banner-button-outer {
    position: relative;
}

.banner-button-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}