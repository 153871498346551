.accountBalance{
  font-size: 28px;
  font-weight: bold;
  color: var(--bs-primary-one);
}

.darkGrayColor{
  color: var(--bs-bodytext-darkthree);
  font-size: 14px;
}
