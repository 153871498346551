/* Common */

:root {
    --bs-primary-one: #00505A;
    --bs-primary-two: #CA7158;
    --bs-primary-three: #321C16;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-white_opacity60: #ffffff99;
    --bs-light: #FAFAF9;
    --bs-bodytext-darkone: #232323;
    --bs-bodytext-darktwo: #4F4F4F;
    --bs-bodytext-darkthree: #7B7B7B;
    --bs-link-color: #00505A;
    --bs-highlight-color: #00505A;
    --bs-border_one: #CED4DA;
    --bs-border_two: #A7A7A7;
    --bs-brand-gradient: linear-gradient(270deg, #003036 0%, #004048 50%, #00505A 100%);
    --bs-italianfontcolor: #CCDCDE;

    /* Wizard Variables */

    --bs-step-next-fill: #F2F6F7;
    --bs-step-next-border: #F2F6F7;
    --bs-step-next-text: rgba(0, 64, 72, 0.8);
    --bs-step-current: #00505A;
    --bs-step-current-border: #00505A;
    --bs-step-current-text: #fff;
    --bs-step-complete: #00505A;
    --bs-step-complete-tick: #fff;
    --bs-step-complete-border: #00505A;

    --bs-step-next-line: #CCDCDE;
    --bs-step-completed-line: #00505A;

    /* form controls variable */
    --bs-radiocheckbox-border: #99B9BD;
}

/* Buttons */

.btn-primary_one {
    --bs-btn-color: #fff;
    --bs-btn-bg: #00505A;
    --bs-btn-border-color: #00505A;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #004048;
    --bs-btn-hover-border-color: #004048;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003036;
    --bs-btn-active-border-color: #003036;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #00505A;
    --bs-btn-disabled-border-color: #00505A;
}
.btn-primary_two {
    --bs-btn-color: #fff;
    --bs-btn-bg: #CA7158;
    --bs-btn-border-color: #CA7158;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #975542;
    --bs-btn-hover-border-color: #975542;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #65382C;
    --bs-btn-active-border-color: #65382C;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #CA7158;
    --bs-btn-disabled-border-color: #CA7158;
}
.btn-primary_three {
    --bs-btn-color: #fff;
    --bs-btn-bg: #321C16;
    --bs-btn-border-color: #321C16;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #65382C;
    --bs-btn-hover-border-color: #65382C;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #CA7158;
    --bs-btn-active-border-color: #CA7158;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #321C16;
    --bs-btn-disabled-border-color: #321C16;
}

.btn-primary_one-outlined {
    --bs-btn-color: #00505A;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #00505A;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #004048;
    --bs-btn-hover-border-color: #004048;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003036;
    --bs-btn-active-border-color: #003036;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #00505A;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #00505A;
}

.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-btn-border-radius: 0;
  }

  