.subHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #232323;
}

.imageStyle {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 15px;
}

.memorableContainer {
  display: flex;
  justify-content: center;
  padding: 90px 0px 90px 0px;
}

.container {
  border: 2px solid var(--bs-border_one);
  align-content: center;
  max-width: 921px;
  width: 100%;
}

.memorableInputStyle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.errorText {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}
