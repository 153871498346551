.category-details-content {
    padding: 15px 15px 20px 15px;
}
.savings-selectaccount-container .account-item-parent{
    height: 100%;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    background-color: var(--bs-light);
}

.category-title{
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto Flex';
    margin-top: 10px;
}

.account-item-parent .pointer-point {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
}

.container.savings-selectaccount-container{
    margin-top: 2em;
}

.row.category-cards-row{
    margin-top: 2rem;
}

.icon-div-choosePlan{
    text-align: center;
}

.terms-conditions-choosePlan {
    border: 1px solid var(--bs-radiocheckbox-border);
    padding: 8px 10px;
}

.terms-conditions-choosePlan.search-input{
    margin-top: 1rem;
}

.terms-conditions-choosePlan.consent-choosePlan{
    margin-top: 1rem;
}
.wizard_taxcitizenship .consent-choosePlan{
    margin-top: 0;
    margin-bottom: 1rem;
}
.wizard_taxcitizenship .consent-choosePlan:last-child, 
.wizard_taxcitizenship .terms-conditions-choosePlan.search-input:last-child {
    margin-bottom: 0;
}
.wizard_taxcitizenship .terms-conditions-choosePlan.search-input{
    margin-top: 0;
    margin-bottom: 1rem;
}

.activeSelected {
    border: 3px solid var(--bs-primary-one);
}

.tc-consent-parent{
    padding: 0px;
    margin-top: 1rem;
}

.Stepper-title h3{
    margin-bottom: 1.5rem;
    margin-top: 2rem;
}

.div-margin-top{
    margin-top : 2rem;
}
.new-line {
    white-space: break-spaces;
}

h2.accordion-header button, h2.accordion-header button:focus{
    background-color: transparent !important;
    box-shadow: none;
}

h2.accordion-header button{
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
}
.accordion-item .accordion-body {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 15px;
    font-size: 14px;
}

.select-drop-down{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

.select-input-box{
        display: inline-flex;
        background-color: #e9ecef;
        border-radius: 5px;
        padding-left: 9px;
        padding-right: 9px;
        border: 1px solid #adb5bd;
        align-items: center;
        margin: 3px;
        gap:7px;
}

.margin-left-10{
    margin-left: 10
}

.margin-top-5{
    margin-top: 5px;
}

.display-flex{
    display: flex;
}

.select-span{
    font-size: 22px; 
    cursor: pointer;
}


.summary-bottom-area-section ul{
    padding-left: 15px;
}

.infoTextSummaryWithIcon{
    margin-left: 5px;
    margin-top: 1px;
    text-decoration: underline;
}

.download-icon-open-link img{
    background-color: var(--bs-primary-one);
    padding: 10px;
    border-radius: 4px;
}

// Summary Box Modal CSS
.customHowWeUseDataModal{
    .useDataModalFooter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 0.5rem 0rem;
    }
    .modal-header, .modal-footer{
        padding: 1rem 2rem;
    }
    .modal-body{
        padding: 0rem 2rem;
    }
}

//FSCS Protection and Terms and Conditions CSS
.fscsAndTermsSec1, .fscsAndTermsSec2 {
    .form-check.customcheckbox{
        margin-bottom: 0px;
    }

}

.cursor-pointer{ 
    cursor: pointer;
}

//Employment Details CSS
.employementDetailsSection{
    .customEmploymentInfoText{
        cursor: pointer;
        .infoTextSummaryWithIcon{
            text-decoration: none;
        }
    }
}
.onboardingCancelModal .modal-dialog{
    max-width: 600px;
}
